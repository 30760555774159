module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"proper-ly","accessToken":"MC5YckswREJBQUFDTUFsUW1C.R--_vR7vv71HGyRqD--_vWvvv71C77-977-9Ugzvv73vv73vv73vv73vv70g77-9Qu-_ve-_vXcH77-977-9Sg","schemas":{"homepage":{"Main":{"banner":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Banner"}}}},"landingpage":{"Main":{"logo":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Logo"}},"coming_soon":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Coming Soon"}},"value_proposition":{"type":"StructuredText","config":{"single":"heading1, heading2, heading3, heading4, heading5, heading6","label":"Value Proposition"}},"background":{"type":"StructuredText","config":{"multi":"paragraph","label":"Background"}},"product_features":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, o-list-item","label":"Product Features"}},"image_one":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Image One"}},"image_two":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Image Two"}},"copyright":{"type":"StructuredText","config":{"single":"paragraph","label":"Copyright"}}}}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
